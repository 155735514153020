@use '@/styles/global' as g;

$yellow_box_color: #ffd217;
$cyan_box_color: #31c8ff;

.listen_now {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2.5rem;

    @include g.bp(md) {
        max-width: 100%;
    }

    @include g.bp(lg) {
        max-width: 100%;
    }
}

.stella_cta_wrapper {
    justify-content: flex-end;
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: 100%;
    /* Full removal pending decision - CECM-33475
    gap: 1rem; */
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
        /* Full removal pending decision - CECM-33475
        gap: 2rem; */
    }
}

.stella_wrapper {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 58px;
    height: 58px;
    margin-right: 8px;

    @include g.bp(md) {
        width: 100px;
        height: 100px;
    }
}

.stella_image {
    aspect-ratio: 1.03;
    object-fit: contain;
    object-position: center;
    fill: g.$blue;
    margin-bottom: 30px;
    width: 58px;
    height: 58px;

    @include g.bp(md) {
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
    }
}

.cta_width {
    @include g.bp(lg) {
        width: 657px !important;
    }
}

.main_cta_wrapper {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    color: g.$white;
    justify-content: flex-start;
    @include g.font-size(48px);
    @include g.set-font-weight('black');

    a {
        text-decoration: none;
        color: g.$white;
    }

    @include g.bp(lg) {
        width: 657px;
    }
}

.main_cta_container {
    background: g.$blue;
    background-color: g.$blue;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex: 1;
    @include g.font-size(24px);

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(48px);
        padding-left: 56px;
        padding-right: 108px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.main_content_wrapper {
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow: column;

    @include g.bp(md) {
        max-width: 100%;
        flex-flow: column-reverse;
        gap: 24px;
        justify-content: flex-start;
    }

    @include g.bp(lg) {
        max-width: 100%;
        flex-flow: row;
        gap: 24px;
        background: g.$blue;
        background-color: g.$blue;
        justify-content: flex-start;
    }
}

.listen_now_cohort_content_container {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    min-width: 240px;
    padding-top: 40px;
    justify-content: space-between;
    flex: 1;
    flex-basis: 0%;
    order: 1;

    @include g.bp(md) {
        display: flex;
        max-width: 100%;
        order: 0;
        margin-top: 0px;
        flex-direction: column;
        min-height: 383px;
    }

    @include g.bp(lg) {
        display: inline-flex;
        max-width: 100%;
        order: 0;
        margin-top: 0px;
        flex-direction: column;
        min-height: 383px;
    }
}

.listen_now_cohort_background {
    width: 100%;
    height: 100%;
}

.listen_now_cohort_background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include g.bp(md) {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
        z-index: 1;
    }
}

.listen_now_cohort_background_image {
    aspect-ratio: 16/9;
    position: relative;
    width: 100%;
    object-fit: cover;
    object-position: center;
    flex: 1;
    flex-basis: 0%;
    min-height: 230px;

    @include g.bp(md) {
        aspect-ratio: auto;
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        flex: 0 1 auto;
        flex-basis: auto;
    }
}

.listen_now_cohort_content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: g.$black;
    justify-content: center;
    padding: 0 0 0 16px;

    @include g.bp(md) {
        max-width: 700px;
        padding-left: 20px;
        justify-content: flex-start;
        color: g.$white;
        padding: 0 8px 0 min(5.5vw, 108px);
    }
}

.listen_now_cohort_content_title {
    max-width: 500px;
    @include g.font-size(24px);
    @include g.set-font-weight('bold');
    line-height: 1.5rem;
    text-transform: uppercase;
    z-index: 3;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(32px);
        @include g.set-font-weight('black');
        line-height: 2.25rem;
    }
}

.listen_now_cohort_content_text {
    max-width: 500px;
    @include g.font-size(16px);
    line-height: 1.25rem;
    margin: 4px 0;
    z-index: 3;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(28px);
        line-height: 1.75rem;
    }
}

.sirius_logo_wrapper {
    position: relative;
    display: none;
    margin-top: 12px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 3;

    @include g.bp(md) {
        max-width: 100%;
        display: flex;
    }
}

.sirius_logo_container {
    display: flex;
    padding-top: 22px;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    width: 96px;
}

.white_box {
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: min(5.5vw, 108px);
    height: 68px;

    @include g.bp(lg) {
        width: min(7.5vw, 108px);
    }
}

.sirius_logo_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 96px;
    align-self: stretch;
    margin: auto 0;
}

.black_background {
    background-color: g.$black;
}

.cohort_channel_reference {
    position: relative;
    display: none;
    margin-top: 12px;
    min-height: 48px;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
        display: flex;
    }
}

.color_box {
    display: none;
    justify-content: flex-start;
    height: 100%;
    width: min(7.5vw, 108px);
    z-index: 3;

    @include g.bp(md) {
        display: flex;
    }

    @include g.bp-max(lg) {
        width: min(5.5vw, 108px);
    }
}

.black_box {
    background: g.$black;
    background-color: g.$black;
    display: flex;
    width: min(2vw, 28px);
    height: 48px;
}

.yellow_box {
    background: $yellow_box_color;
    background-color: $yellow_box_color;
    display: flex;
    width: 80px;
    height: 48px;
    flex: 1;
    flex-basis: 0%;
}

.cohort_channel_button_mobile {
    justify-content: center;
    align-items: center;
    background: g.$black;
    background-color: g.$black;
    color: g.$white;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 0.875rem;
    height: 100%;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 1rem;
    width: 205px;
    margin-left: calc(100vw - 205px);

    @include g.bp(md) {
        display: none;
    }
}

.cohort_channel_button {
    justify-content: center;
    align-items: center;
    background: g.$black;
    background-color: g.$black;
    color: g.$white;
    display: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 0.875rem;
    height: 100%;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 1rem;

    @include g.bp(md) {
        background: g.$white;
        background-color: g.$white;
        color: g.$black;
        display: flex;
    }
}

.cohort_channel_button_text {
    align-self: stretch;
    margin: auto 0;
}

.arrow_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 14px;
    fill: g.$black;
    align-self: stretch;
    height: 14px;
    margin: auto 0;
}

.listen_now_wrapper {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: flex-start;

    @include g.bp(md) {
        max-width: 100%;
    }

    @include g.bp(lg) {
        max-width: 100%;
        width: 633px;
    }
}

.listen_now_container {
    background: g.$off-white;
    background-color: g.$off-white;
    display: flex;
    width: 100%;
    padding: 16px 16px 0 16px;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    @include g.bp(md) {
        max-width: 100%;
        padding: 0 20px 0 min(5.5vw, 108px);
    }

    @include g.bp(lg) {
        max-width: 100%;
        padding: 0 108px 0 32px;
    }
}

.car {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;

    @include g.bp(md) {
        max-width: 100%;
        padding-top: 56px;
        flex-direction: column;
    }
}

.image_text_wrapper {
    display: flex;
    margin-top: 0px;
    width: 100%;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
        margin-top: 1rem;
        align-items: center;
        justify-content: flex-start;
    }
}

.image_text_wrapper_end {
    display: flex;
    margin-top: 0px;
    width: 100%;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
        margin-top: 16px;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.car_image_wrapper {
    display: flex;
    min-height: 59px;
    flex-direction: column;
    justify-content: center;
    height: 80px;
}

.car_image {
    aspect-ratio: 1.78;
    object-fit: contain;
    object-position: center;
    width: 142px;
    height: 100%;
}

.app {
    display: flex;
    margin-top: 24px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;

    @include g.bp(md) {
        max-width: 100%;
        padding-bottom: 56px;
    }
}

.app_block {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-start;

    @include g.bp(md) {
        max-width: 100%;
        flex-direction: column;
    }
}

.list_title {
    color: g.$black;
    @include g.font-size(18px);
    @include g.set-font-weight('black');
    line-height: 1.25rem;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(28px);
    }
}

.mobile_image_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 105px;

    @include g.bp(md) {
        display: none;
    }
}

.desktop_image_wrapper {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include g.bp(md) {
        display: flex;
    }
}

.mobile_image {
    object-fit: contain;
    object-position: center;
    width: 142px;
    max-width: 100%;

    @include g.bp(md) {
        aspect-ratio: 1.75;
    }
}

.list_text {
    color: g.$almost-black;
    display: flex;
    flex-basis: 0%;
    font-weight: 400;
    @include g.font-size(14px);

    @include g.bp(md) {
        @include g.font-size(20px);
        display: none;
        flex: 1;
    }
}

.text_wrap_mobile {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 8px;

    @include g.bp(md) {
        display: relative;
        align-self: auto;
    }
}

.list_text_desktop {
    color: g.$black;
    flex: 1;
    flex-basis: 0%;
    font-weight: 400;
    @include g.font-size(14px);
    display: none;

    @include g.bp(md) {
        @include g.font-size(20px);
        display: flex;
    }
}

.appLinkHref {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    text-decoration: none;
    color: g.$blue;
}

.link {
    display: none;

    @include g.bp(md) {
        @include g.font-size(18px);
        max-width: 100%;
        display: flex;
        margin-top: 24px;
        width: 100%;
        align-items: center;
        gap: 1rem;
        color: g.$blue;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

.link_mobile {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
    color: g.$blue;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include g.font-size(14px);
    vertical-align: top;

    @include g.bp(md) {
        display: none;
    }
}

.listen_now_link_wrapper {
    align-self: stretch;
    margin: auto 0;
}

.white_arrow_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 1rem;
    fill: g.$blue;
    align-self: stretch;
    margin: auto 0;
}

.lower_margin {
    justify-content: flex-end;
    align-items: flex-start;
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: 100%;

    @include g.bp(md) {
        max-width: 100%;
    }
}

.lower_margin_blue {
    background: g.$white;
    background-color: g.$white;
    display: flex;
    min-height: 1.5rem;
    min-width: 240px;
    width: 657px;

    @include g.bp(md) {
        width: 100%;
    }

    @include g.bp(lg) {
        width: 657px;
        background: g.$blue;
        background-color: g.$blue;
        margin-bottom: 2.5rem;
    }
}

.sirius_logo_mobile_wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;

    @include g.bp(md) {
        display: none;
    }
}

.sirius_logo_mobile_image_container {
    display: flex;
    padding-top: 1.375rem;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    width: 68px;
}

.sirius_mobile_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 68px;
    align-self: stretch;
    margin: auto 0;
    background-color: g.$black;
}

.teal_box {
    background: $cyan_box_color;
    background-color: $cyan_box_color;
    display: flex;
    width: 16px;
    height: 68px;
}

.content_layout_mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0;
    align-self: stretch;
    padding-top: 8px;
    padding-right: 16px;
    min-width: 185px;

    @include g.bp(sm) {
        width: auto;
    }

    @include g.bp(md) {
        display: inline;
        margin: 0, 0, 0, 0;
        width: 100%;
    }
}

.listen_now_mobile_cohort_background {
    display: flex;
    height: 100%;
    width: 70%;
    align-self: flex-end;

    @include g.bp(md) {
        display: inline;
        width: 0px;
        height: 0px;
    }
}

.view_more_link {
    text-decoration: none;
    color: g.$white;
    z-index: 3;

    @include g.bp(md) {
        color: g.$black;
    }
}

.hidden {
    display: none;
}

.shown {
    display: flex;
}

.mobile_cta_wrapper {
    display: none;

    a {
        text-decoration: none;
        color: g.$white;
    }
}

.conversion {
    .main_cta_wrapper,
    .lower_margin_blue {
        width: min(60%, 800px);
        min-width: 780px;

        @include g.bp-max(lg) {
            width: 80%;
            min-width: unset;
        }
    }
    .stella_cta_wrapper {
        display: none;

        @include g.bp(md) {
            display: flex;
        }
    }

    .mobile_cta_wrapper {
        display: flex;
        flex-direction: column;

        @include g.bp(md) {
            display: none;
        }
    }

    .stella_wrapper {
        width: 18%;
        height: auto;

        @include g.bp(lg) {
            width: 170px;
            height: 170px;
            margin-right: 8px;
        }
    }
}
