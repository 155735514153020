@use '@/styles/global' as g;

$orange_box_color: rgba(255, 97, 41, 1);
$yellow_box_color: #ffd217;
$cyan_box_color: #31c8ff;

.listen_now {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2.5rem;

    @include g.bp(md) {
        max-width: 100%;
    }

    @include g.bp(lg) {
        max-width: 100%;
    }
}

.stella_cta_wrapper {
    justify-content: flex-end;
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
    }
}

.stella_wrapper {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 58px;
    height: 58px;

    @include g.bp(md) {
        width: 100px;
        height: 100px;
        margin-right: 8px;
    }
}

.stella_image {
    aspect-ratio: 1.03;
    object-fit: contain;
    object-position: center;
    fill: g.$blue;
    margin-bottom: 0px;
    width: 58px;
    height: 56px;

    @include g.bp(md) {
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
    }
}

.main_cta_wrapper {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    color: g.$white;
    justify-content: flex-start;
    @include g.font-size(48px);
    @include g.set-font-weight('black');

    a {
        text-decoration: none;
        color: g.$white;
    }

    @include g.bp(md) {
        width: 657px;
    }
}

.main_cta_container {
    background: g.$blue;
    background-color: g.$blue;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex: 1;
    @include g.font-size(24px);

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(48px);
        padding-left: 56px;
        padding-right: 108px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.main_content_wrapper {
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow: column;

    @include g.bp(md) {
        max-width: 100%;
        flex-flow: column-reverse;
        gap: 24px;
        justify-content: flex-start;
    }

    @include g.bp(lg) {
        max-width: 100%;
        flex-flow: row;
        gap: 24px;
        background: g.$blue;
        background-color: g.$blue;
        justify-content: flex-start;
    }
}

.listen_now_cohort_content_container {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 280px;
    margin-top: 40px;
    padding: 16px 0;
    flex: 1;
    flex-basis: 0%;
    order: 1;
    background-color: rgba(0, 0, 0, 0.9);
    height: 475px;

    @include g.bp(md) {
        display: flex;
        max-width: 100%;
        order: 0;
        margin-top: 0px;
        flex-direction: column;
        min-height: 475px;
        padding: 0;
    }

    @include g.bp(lg) {
        display: inline-flex;
        max-width: 100%;
        order: 0;
        margin-top: 0px;
        flex-direction: column;
        height: unset;
        min-height: unset;
    }

    @include g.bp(xl) {
        aspect-ratio: 16 / 9;
    }
}

.listen_now_cohort_background {
    width: 100%;
    height: 100%;
}

.listen_now_cohort_background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include g.bp(md) {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
        z-index: 1;
    }
}

.listen_now_cohort_background_image {
    aspect-ratio: 15/8;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    flex: 1;
    flex-basis: 0%;

    @include g.bp(md) {
        aspect-ratio: auto;
        position: absolute;
        inset: 0;
        width: 100%;
        object-fit: cover;
        flex: 0 1 auto;
        flex-basis: auto;
    }
}

.listen_now_cohort_background_overlay {
    z-index: 2;
    width: 100%;
    position: absolute;

    .white_box {
        position: absolute;
        width: 32px;
        height: 48px;
        top: 0;
        left: 0;
        background-color: g.$white;
    }

    .color_box_mobile {
        display: flex;
        align-items: flex-start;
        position: absolute;
        height: 16px;
        bottom: 0;
        right: 0;
        flex-direction: row-reverse;

        .x,
        .y {
            align-self: stretch;
        }

        .y {
            width: 32px;
            background-color: g.$black;
        }

        .x {
            width: 64px;
            background-color: $orange_box_color;
        }
    }

    @include g.bp(md) {
        display: none;
    }
}

.listen_now_cohort_content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: g.$white;
    justify-content: center;
    padding: 0 16px 16px;
    gap: 8px;

    @include g.bp(md) {
        max-width: 700px;
        padding-left: 20px;
        justify-content: flex-start;
        padding: min(4.5vw, 80px) min(7vw, 108px) 0;
    }
}

.listen_now_cohort_content_title {
    @include g.font-size(24px);
    @include g.set-font-weight('black');
    line-height: 1.5rem;
    z-index: 3;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(32px);
        @include g.set-font-weight('black');
        line-height: 2.25rem;
    }
}

.listen_now_cohort_content_text {
    @include g.font-size(16px);
    @include g.set-font-weight('book');
    line-height: 1.25rem;
    z-index: 3;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(18px);
        line-height: 1.5rem;
    }
}

.listen_now_cohort_content_subtext {
    @include g.font-size(14px);
    @include g.set-font-weight('light');
    line-height: 1.25rem;
    z-index: 3;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(18px);
        @include g.set-font-weight('book');
        line-height: 1.5rem;
        margin-top: 40px;
    }
}

.cohort_channel_reference {
    position: absolute;
    bottom: 0;
    display: none;
    margin-top: 12px;
    min-height: 48px;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;

    @include g.bp(md) {
        max-width: 100%;
        display: flex;
    }
}

.color_box {
    display: none;
    justify-content: flex-start;
    width: 108px;
    z-index: 3;

    @include g.bp(md) {
        display: flex;
    }
}

.black_box {
    background: g.$black;
    background-color: g.$black;
    display: flex;
    width: 28px;
    height: 52px;
}

.orange_box {
    background: $orange_box_color;
    background-color: $orange_box_color;
    display: flex;
    width: 80px;
    height: 52px;
    flex: 1;
    flex-basis: 0%;
}

.cohort_channel_button_mobile {
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    background: g.$white;
    background-color: g.$white;
    color: g.$black;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 0.875rem;
    padding: 8px 16px;
    @include g.font-size(16px);
    max-width: fit-content;

    @include g.bp(md) {
        display: none;
    }
}

.cohort_channel_button {
    justify-content: center;
    align-items: center;
    display: none;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 0.875rem;
    height: 100%;
    padding: 8px 16px;
    @include g.font-size(16px);

    @include g.bp(md) {
        background: g.$white;
        background-color: g.$white;
        color: g.$black;
        display: flex;
    }
}

.cohort_channel_button_text {
    align-self: stretch;
    margin: auto 0;
}

.arrow_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 14px;
    fill: g.$black;
    align-self: stretch;
    height: 14px;
    margin: auto 0;
}

.listen_now_wrapper {
    align-self: flex-start;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;

    @include g.bp(md) {
        max-width: 100%;
    }

    @include g.bp(lg) {
        max-width: 100%;
        width: 633px;
    }
}

.listen_now_container {
    background: g.$off-white;
    background-color: g.$off-white;
    display: flex;
    width: 100%;
    padding: 16px 16px 0 16px;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    @include g.bp(md) {
        max-width: 100%;
        padding: 0 20px 0 min(7vw, 108px);
    }

    @include g.bp(lg) {
        max-width: 100%;
        padding: 0 60px 0 32px;
        justify-content: space-evenly;
    }
}

.car {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;

    @include g.bp(md) {
        max-width: 100%;
        padding-top: 48px;
        flex-direction: column;
    }

    @include g.bp(lg) {
        gap: 10px;
        padding-top: 32px;
    }
}

.image_text_wrapper {
    display: flex;
    margin-top: 0px;
    width: 100%;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
        margin-top: 1rem;
        align-items: center;
        justify-content: flex-start;
    }
}

.image_text_wrapper_end {
    display: flex;
    margin-top: 0px;
    width: 100%;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include g.bp(md) {
        max-width: 100%;
        margin-top: 16px;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.car_image_wrapper {
    display: flex;
    min-height: 59px;
    flex-direction: column;
    justify-content: center;
    height: 80px;

    @include g.bp(lg) {
        height: 56px;
    }

    @include g.bp(xl) {
        width: 150px;
        height: 80px;
        align-items: center;
    }
}

.car_image {
    aspect-ratio: 1.78;
    object-fit: contain;
    object-position: center;
    width: 142px;
    height: 100%;
}

.app {
    display: flex;
    margin-top: 24px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;

    @include g.bp(md) {
        max-width: 100%;
        padding-bottom: 48px;
    }
}

.app_block {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-start;

    @include g.bp(md) {
        max-width: 100%;
        flex-direction: column;
    }

    @include g.bp(lg) {
        gap: 10px;
    }
}

.list_title {
    color: g.$black;
    @include g.font-size(18px);
    @include g.set-font-weight('black');
    line-height: 1.25rem;

    @include g.bp(md) {
        max-width: 100%;
        @include g.font-size(28px);
    }
}

.mobile_image_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 105px;

    @include g.bp(md) {
        display: none;
    }
}

.desktop_image_wrapper {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;

    @include g.bp(md) {
        display: flex;
        width: 143px;
    }
    @include g.bp(lg) {
        height: 56px;
    }
    @include g.bp(xl) {
        height: 80px;
    }
}

.mobile_image {
    object-fit: contain;
    object-position: center;
    width: 142px;
    max-width: 100%;

    @include g.bp(md) {
        aspect-ratio: 1.75;

    }
}

.list_text {
    color: g.$almost-black;
    display: flex;
    flex-basis: 0%;
    @include g.font-size(14px);

    @include g.bp(md) {
        @include g.font-size(20px);
        display: none;
        flex: 1;
    }

    @include g.bp(xl) {
        @include g.font-size(28px);
        display: none;
        flex: 1;
    }
}

.text_wrap_mobile {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 8px;

    @include g.bp(md) {
        display: relative;
        align-self: auto;
    }
}

.list_text_desktop {
    color: g.$black;
    flex: 1;
    flex-basis: 0%;
    @include g.font-size(14px);
    display: none;

    @include g.bp(md) {
        @include g.font-size(18px);
        display: flex;
    }

    @include g.bp(xl) {
        @include g.font-size(20px);
        display: flex;
    }
}

.appLinkHref {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    text-decoration: none;
    color: g.$blue;
}

.link {
    display: none;

    @include g.bp(md) {
        @include g.font-size(18px);
        max-width: 100%;
        display: flex;
        margin-top: 1rem;
        width: 100%;
        align-items: center;
        gap: 1rem;
        color: g.$blue;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    @include g.bp(lg) {
        margin-top: 2rem;
    }

    @include g.bp(xl) {
        @include g.font-size(24px);
    }
}

.link_mobile {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.5rem;
    color: g.$blue;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include g.font-size(14px);
    vertical-align: top;

    @include g.bp(md) {
        display: none;
    }
}

.listen_now {
    align-self: stretch;
    margin: auto 0;
}

.lower_margin {
    justify-content: flex-end;
    align-items: flex-start;
    background: g.$white;
    background-color: g.$white;
    display: flex;
    width: 100%;

    @include g.bp(md) {
        max-width: 100%;
    }
}

.lower_margin_blue {
    background: g.$white;
    background-color: g.$white;
    display: flex;
    @include g.font-size(24px);
    min-height: 1.5rem;
    min-width: 240px;
    width: 657px;

    @include g.bp(md) {
        background: g.$white;
        background-color: g.$white;
    }

    @include g.bp(lg) {
        background: g.$blue;
        background-color: g.$blue;
    }
}

.sirius_mobile_image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 68px;
    align-self: stretch;
    margin: auto 0;
    background-color: g.$black;
}

.content_layout_mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    @include g.bp(md) {
        display: none;
    }
}

.content_layout_desktop {
    display: none;
    width: 100%;

    @include g.bp(md) {
        display: inline;
        margin: 0, 0, 0, 0;
        width: 100%;
    }
}

.listen_now_mobile_cohort_background {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    aspect-ratio: 15/8;

    @include g.bp(md) {
        display: inline;
        width: 0px;
        height: 0px;
        position: unset;
    }
}

.view_more_link {
    text-decoration: none;
    color: g.$black;
    z-index: 3;

    @include g.bp(md) {
        color: g.$black;
    }
}

.hidden {
    display: none;
}

.shown {
    display: flex;
}

.mobile_cta_wrapper {
    display: none;

    a {
        text-decoration: none;
        color: g.$white;
    }
}

.conversion {
    .main_content_wrapper {
        aspect-ratio: auto;

        @include g.bp(lg) {
            aspect-ratio: 10/3;
        }
    }
    
    .listen_now_cohort_content_container {
        aspect-ratio: unset;
    }

    .main_cta_wrapper,
    .lower_margin_blue {
        width: min(60%, 800px);
        min-width: 780px;

        @include g.bp-max(lg) {
            width: 80%;
            min-width: unset;
        }
    }

    .stella_cta_wrapper {
        display: none;

        @include g.bp(md) {
            display: flex;
        }
    }

    .stella_wrapper {
        width: 18%;
        height: auto;
        @include g.bp(lg) {
            width: 170px;
            height: 170px;
            margin-right: 0px;
        }
    }

    .mobile_cta_wrapper {
        display: flex;
        flex-direction: column;

        @include g.bp(md) {
            display: none;
        }
    }
}
