@use '@/styles/global' as g;

$hero-min-height-mobile: 272px;
$hero-min-height-desktop: 464px;
$hero-min-height-mobile-team: 428px;
$hero-min-height-desktop-team: 390px;

.hero {
    display: flex;
    min-height: $hero-min-height-mobile;

    @include g.bp(md) {
        a {
            color: inherit;
            background-color: transparent;
        }

        min-height: $hero-min-height-desktop;
    }

    &.teamHero {
        min-height: $hero-min-height-mobile-team;

        @include g.bp(md) {
            min-height: $hero-min-height-desktop-team;
        }
    }
}

.layoutPadding {
    padding-top: map-get(g.$page-layout-spacing, micro);
    padding-bottom: map-get(g.$page-layout-spacing, micro);

    @include g.bp(md) {
        padding-top: map-get(g.$page-layout-spacing, venti);
        padding-bottom: map-get(g.$page-layout-spacing, trenta);
    }
}

.widgetPhoneNumber {
    padding-bottom: map-get(g.$page-layout-spacing, micro);
    text-align: center;

    @include g.bp(md) {
        max-width: 496px;
        margin: 24px 0 24px auto;
    }
}

.heroText {
    h1 {
        margin-bottom: 8px;

        * {
            // Removing margins of CMS-rendered inner elements in favor of our own:
            margin: 0;
        }
    }

    .heroTextHeader {
        margin-bottom: 0px;
    }

    .priceSubhead {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .offerIcons {
        margin-top: 24px;

        @include g.bp(md) {
            margin-top: 32px;
            margin-bottom: 16px;
            padding-bottom: 16px;
        }
    }

    .ctaButton {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    .hideCtaOnMobile {
        display: none;

        @include g.bp(md) {
            display: block;
        }
    }

    .socialMediaBlock {
        &StickToBottom {
            margin-top: 32px;

            @include g.bp(md) {
                margin-top: 72px;
                margin-bottom: -52px;
            }
        }

        .socialMediaIcons {
            column-gap: 16px;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.foregroundImageWrapper {
    height: 100%;
    // min-height: 370px; //commenting this line for now to fix the regression bug.
    width: 100%;
    position: relative;

    @include g.bp(md) {
        min-height: calc($hero-min-height-desktop - map-get(g.$nav-height, md));
    }

    img {
        @include g.bp(md) {
            object-fit: contain;
            height: 100%;
            position: absolute;
            width: auto;
            top: auto;
            left: auto;
            right: 0;
            bottom: auto;
            max-width: 100%;
        }
    }

    &.stretchImage {
        @include g.bleed(md);
    }

    &.hiddenDesktop {
        @include g.bp(md) {
            display: none;
        }
    }
}

.backgroundLogo {
    position: absolute;
    left: 50%;
    top: 70px;
    width: 284px;
    transform: translate(-50%, 0);
    opacity: 0.3;

    img {
        max-height: 284px;
    }

    @include g.bp(md) {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.imageToBottom {
    align-items: flex-end;

    img {
        object-position: bottom;
    }
}

.mobileFlatBackground {
    @include g.bp-max(md) {
        color: var(--black);
        background: var(--white);
        padding-left: map-get(g.$grid-paddings, sm);
        padding-right: map-get(g.$grid-paddings, sm);

        .socialMediaIcons svg {
            fill: g.$black;
        }

        .ctaButton {
            background: var(--secondary-atlas-web-blue);
            color: var(--white);

            &:hover,
            &:focus,
            &:active {
                color: var(--secondary-atlas-web-blue);
                background: transparent;
            }

            &::before {
                border-color: var(--secondary-atlas-web-blue);
            }
        }
    }

    @include g.bp-max(sm) {
        padding-left: map-get(g.$grid-paddings, xs);
        padding-right: map-get(g.$grid-paddings, xs);
    }

    @include g.bleed(md);
}

.subheadNarrow {
    .subhead {
        @include g.bp(lg) {
            width: 83%; // roughly 5 columns of the 6 available
        }
    }
}

.withBreadcrumbs {
    padding-top: map-get(g.$page-layout-spacing, micro);
}

.widgetRightColumn {
    position: relative;
    height: 100%;
    padding: 0;

    @include g.bp(md) {
        min-height: 392px;
        padding: 0 16px;
    }
}

.widgetRightColumn,
.noWidgetBackground {
    background: none;
}


.heroUnderCtaText {
    @include g.web2PageOverrides {
        @include g.bp(md) {
            a {
                color: inherit;
            }
        }
    }

}