@use '@/styles/global' as g;

.hoverLink {
    display: block;
    a {
        text-decoration: none;
    }

    &:hover {
        .hoverForeground {
            background: g.$black;
            opacity: 0.5;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            @include g.web2PageOverrides {
                border-radius: 24px;
            }
        }
    }
}

.mediaElement {
    margin-bottom: 12px;
    min-height: 265px;
}

.featured {
    @include g.bp-max(md) {
        width: 33.33%;
    }
}

.mediaContent {
    @include g.bp-max(md) {
        width: 66.66%;
        padding: 0 12px 0 24px;

        span {
            @include g.font-size(14px);
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
}

.mediaRowFeatured {
    @include g.bp-max(md) {
        display: flex;
    }
}

.mediaElementSingle {
    margin-bottom: 12px;

    @include g.bp(md) {
        margin-bottom: 0;
    }
}

.headline {
    margin-bottom: 8px;
    line-height: 1.5;
}

.headline,
.headline > a {
    color: g.$black;
    margin-top: 0;
    text-decoration: none;
    @include g.web2PageOverrides {
        color: g.$black;
    }
}

.contentCard.compactTextSpacing {
    .headlineAlt,
    .headline {
        margin-bottom: 8px;

        * {
            margin: 0;
            padding: 0;
        }
    }
}

.headlineAsLink > p {
    margin-bottom: 0;
    margin-top: 0;
}

.headlineAsLink {
    color: g.$black;
    margin-bottom: 8px;
    text-decoration: none;
    display: block;
    line-height: 1.5;

    a {
        text-decoration: none;
    }
    @include g.web2PageOverrides {
        color: g.$black;
    }
}

.headlineAsLink:hover {
    color: g.$hover-blue;

    @include g.web2PageOverrides {
        color: var(--base-contrast-accent, #0000eb);
    }

    .headlineAlt,
    .headline {
        color: g.$hover-blue;
        @include g.web2PageOverrides {
            color: var(--base-contrast-accent, #0000eb);
        }
    }

    .subHeader {
        color: g.$hover-blue;
        @include g.web2PageOverrides {
            color: var(--base-contrast-accent, #0000eb);
        }
    }


}

.subHeader {
    @include g.font-size(12px);
    margin-bottom: 8px;
    color: g.$gray-semi-dark;
    display: block;
}

.bodyText {
    line-height: 1.57;
    color: var(--almost-black);
}
.contentSamplerBodyText {
    @include g.font-size(16px);
    line-height: 1.5;
    word-wrap: break-word;
}

.contentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.imageIcon {
    max-width: 64px;
    max-height: 64px;
}

.logoImage {
    max-width: 50%;
    max-height: 100%;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.imageOverlay {
    z-index: 1;
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: auto;

    .hoverLink:hover & {
        mix-blend-mode: multiply;
    }

    .hoverLink &:hover {
        mix-blend-mode: normal;
    }
}

.media {
    width: 100%;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    position: relative;
}

.mediaBackgroundColor {
    opacity: 0.25;
    z-index: 1;
    width: 100%;
    padding: 0 0 56.25%;
}

.imageContainer {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    img {
        max-width: 50%;
        max-height: 100%;
        width: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}

.pingPong {
    .contentCard {
        @include g.bp(md) {
            padding-left: 24px;

            &Even {
                padding-left: 0;
                padding-right: 24px;
            }
        }
    }

    &.reversed {
        .contentCard {
            @include g.bp(md) {
                padding-right: 24px;
                padding-left: 0;

                &Even {
                    padding-right: 0;
                    padding-left: 24px;
                }
            }
        }
    }
}

.mediaWithText.partner {
    position: relative;
    margin-bottom: map-get(g.$page-layout-spacing, micro);

    &::after {
        content: '';
        position: absolute;
        grid-column: 1 / span 12;
        width: inherit;
        bottom: -32px;

        @include g.bp(lg) {
            grid-column: 2 / span 10;
        }
    }

    &.separators-all {
        margin-bottom: calc(34px + map-get(g.$page-layout-spacing, micro));

        &::after {
            height: 1px;
            background: var(--gray-light);
        }
    }

    &.separators-end {
        &:last-of-type {
            margin-bottom: calc(32px * 2);

            &:after {
                height: 2px;
                background-color: var(--gray-medium);
            }
        }
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    .bodyText {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ratio169 {
    .contentWrapper {
        position: relative;
        padding-bottom: 56.25%;
        background-color: g.$off-white-dark;

        img {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0);
            height: 100%;
            width: auto;
        }
    }
}

.imageStyles {
    @include g.web2PageOverrides {
        border-radius: 24px;
    }
}